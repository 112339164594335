<template>
  <div class="copyrightMain">
    <display-module :titleInfo="titleInfo" style="padding-top: 109px;margin-bottom:83px;" v-show="showFlag"/>
    <a-carousel arrows id="carouseled" class="animate__animated animate__fadeIn animate__slow" v-show="showFlag">
      <div
        slot="prevArrow"
        slot-scope="props"
        class="custom-slick-arrow rotate0"
        style="left: 10px; z-index: 1"
      >
    </div>
      <div
        slot="nextArrow"
        class="custom-slick-arrow rotate180"
        style="right: 10px;"
      ></div>
      <div class="carouselItem">
        <div>
          <img-item imgName="编组 17" />
          
          <div class="Medium">升学派位管理系统</div>
        </div> 
        <div>
          <img-item imgName="位图备份" />
          <div class="Medium">招生摇号软件</div>
        </div>
        <div>
          <img-item imgName="位图备份 2" />
          <div class="Medium">多创OA管理系统</div>
        </div>
        <div>
          <img-item imgName="位图备份 3" />
          <div class="Medium">多创文件管理系统</div>
        </div>
        <div>
          <img-item imgName="位图备份 4" />
          <div class="Medium">手机约车系统</div>
        </div>
      </div>
      <div class="carouselItem">
        <div>
          <img-item imgName="编组 17 (1)" />
          <div class="Medium">智能调度系统</div>
        </div>
        <div>
          <img-item imgName="位图备份 (1)" />
          <div class="Medium">网约车智能派单算法软件</div>
        </div>
        <div>
          <img-item imgName="位图备份 2 (1)" />
          <div class="Medium">网约车web版管理后台软件</div>
        </div>
        <div>
          <img-item imgName="位图备份 3 (1)" />
          <div class="Medium">上车点优化算法软件</div>
        </div>
        <div>
          <img-item imgName="位图备份 4 (1)" />
          <div class="Medium">分时约车调度优化算法软件</div>
        </div>
      </div>
      <div class="carouselItem">
        <div>
          <img-item imgName="位图备份 3 (2)" />
          <div class="Medium">网约车分段支付算法软件</div>
        </div>
        <div>
          <img-item imgName="位图备份 4 (2)" />
          <div class="Medium">网约车一键下单系统</div>
        </div>
      </div>
    </a-carousel>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
export default {
  components: {
    DisplayModule,
  },
  props: {
    showFlag: Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "copyright",
        CHSTitle: "软件版权著作",
        instructions: [
          "服务客户500+，覆盖众多主流行业，更多客户正在加入我们的合作"
        ],
      },
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.copyrightMain {
  width: 1140px;
  margin: 0 auto 120px;
  position: relative;
}
#carouseled .slick-slide {
  text-align: center;
  overflow: hidden;
}

#carouseled .custom-slick-arrow {
  width: 45px;
  height: 45px;
  margin-top: -40px;
  background-image: url("http://dotron.cn:9000/dtron/编组 13.png");
  background-size:100%;
}
#carouseled .custom-slick-arrow:before {
  display: none;
}
#carouseled .custom-slick-arrow:hover {
  background-image: url("http://dotron.cn:9000/dtron/编组 14.png");
}
#carouseled .slick-dots li button {
  background: #c4c4c4;
}
#carouseled .slick-dots li.slick-active button {
  background: #30999d;
}
.rotate180{
    transform: rotate(180deg);
}
.rotate180:hover{
    transform: rotate(0deg);
}
.rotate0:hover{
    transform: rotate(180deg);
}
.carouselItem {
  display: flex !important;
  justify-content: center;
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
  color: #333939;
}
.carouselItem >>> img {
    width: 165px;
    height: 226px;
  margin: 0 19px 8px;
}
</style>
