<template>
  <div class="aboutUsMain">
    <div class="aboutUsaboutBanner">
      <img-item imgName="编组 10" :styleObj="aboutUsaboutBannerImg" />
      <div class="aboutBannerView">
        <img-item imgName="编组 8" :styleObj="bannerViewImg" />
        <!-- <div class="aboutBannerViewImg"></div>
        <div class="aboutBannerViewTitle">More creativity</div> -->
        <div class="aboutBannerViewLineBox"></div>
        <div class="aboutBannerViewText">
          多创一直致力于网络品牌的特性研究与技术创新
        </div>
        <div class="aboutBannerViewText">
          用具备国际视野的视觉设计与互动技术
        </div>
      </div>
    </div>
    <div ref="datum">
      <datum-part style="margin-top:-58px;" :showFlag="datumShow" />
    </div>
    <div ref="choice">
      <choice-part :showFlag="choiceShow" />
    </div>
    <div ref="environment">
      <environment-part :showFlag="environmentShow" />
    </div>
    <div ref="copyright">
      <copyright-part :showFlag="copyrightShow" />
    </div>
    <map-part />


  </div>
</template>

<script>
// @ is an alias to /src
// import DisplayModule from "@/components/displayModule/index";
import datumPart from "./components/datum";
import choicePart from "./components/choice";
import copyrightPart from "./components/copyright";
import environmentPart from "./components/environment";
import mapPart from "./components/map";
export default {
  name: "aboutUs",
  components: {
    choicePart,
    environmentPart,
    copyrightPart,
    mapPart,
    datumPart
  },
  data() {
    return {
      datumShow: false,
      choiceShow: false,
      environmentShow: false,
      copyrightShow: false,
      bannerViewImg: {
        'width': '349x',
        'height': '200px',
        'margin': '0 auto 18px'
      },
      aboutUsaboutBannerImg: {
        'width': '100%',
        'height': 'auto'
      },
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() { // 实现当滚动到指定位置，触发动画
      let _this = this
      let refArray = [
        { ref: 'datum', show: 'datumShow' },
        { ref: 'choice', show: 'choiceShow' },
        { ref: 'environment', show: 'environmentShow' },
        { ref: 'copyright', show: 'copyrightShow' }
      ]
      refArray.forEach((r, i) => {
        _this[r.show] = _this.gdjz(r.ref, 20)
      })
    },
    gdjz(div, offset) {
      let dom = this.$refs[div]
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset)
        b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
        return b + c > a
      }
    },
  },
  created() { },
};
</script>
<style scoped>
.aboutUsMain {
  width: 100%;
}

.aboutUsaboutBanner {
  width: 100%;
  position: relative;
}

/* .aboutUsaboutBannerImg {
  width: 100%;
  height: auto;
} */
.aboutBannerView {
  position: absolute;
  left: 50%;
  top: 188px;
  margin-left: -200px;
  width: 400px;
  text-align: center;
}

/* .aboutBannerViewImg {
  width: 83px;
  height: 83px;
  margin: 0 auto;
  background-image: url("https://vkceyugu.cdn.bspapp.com/VKCEYUGU-af6b216e-68d1-4cf6-b184-a411c26ab033/f22149cf-2fb3-4e35-9a52-78f718e491ae.png");
  background-position: -69px 0;
} */

.aboutBannerViewTitle {
  font-size: 36px;
  font-family: DIN-BlackItalic, DIN;
  color: #ffffff;

  line-height: 68px;
  letter-spacing: 2px;
}

.aboutBannerViewLineBox {
  width: 68px;
  height: 6px;
  background: #2b7bee;
  margin: 14px auto 12px;
}

.aboutBannerViewText {
  font-size: 18px;
  color: #ffffff;
  line-height: 25px;
  opacity: 0.75;
  letter-spacing: 1px;
}</style>
