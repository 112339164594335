<template>
  <div class="choiceMain">
    <img-item imgName="编组 11" :styleObj="choiceMainImg" />
    <img-item imgName="编组 12" :styleObj="decorationImg" />
    <display-module :titleInfo="titleInfo" style="padding-top: 109px;margin-bottom:83px;" v-show="showFlag" />
    <div class="choiceContent">
      <div class="choiceList">
        <div class="choiceItem itemFlashed" v-show="showFlag">
          <div class="choiceItemTop">
            <img-item imgName="周期短" />
            <div class="Medium">周期短</div>
          </div>
          <div class="choiceItemText">
            高效团队配合开发周期短
          </div>
        </div>
        <div class="choiceItem itemFlashed flashDelay03" v-show="showFlag">
          <div class="choiceItemTop">
            <img-item imgName="系统稳" />
            <div class="Medium">系统稳</div>
          </div>
          <div class="choiceItemText">
            原生开发性能质量优化
          </div>
        </div>
        <div class="choiceItem itemFlashed flashDelay05" v-show="showFlag">
          <div class="choiceItemTop">
            <img-item imgName="品质高" />
            <div class="Medium">品质高</div>
          </div>
          <div class="choiceItemText">
            资深开发团队流程管控
          </div>
        </div>
        <div class="choiceItem itemFlashed flashDelay07" v-show="showFlag">
          <div class="choiceItemTop">
            <img-item imgName="服务好" />
            <div class="Medium">服务好</div>
          </div>
          <div class="choiceItemText" style="height:51px;margin-bottom:30px;">
            热情服务快速响应
          </div>
        </div>
      </div>
      <div class="choiceList">
        <div class="choiceItem itemFlashed" v-show="showFlag">
          <div class="choiceItemTop">
            <img-item imgName="定制化" />
            <div class="Medium">定制化</div>
          </div>
          <div class="choiceItemText">
            专属定制满足个性需求
          </div>
        </div>
        <div class="choiceItem itemFlashed flashDelay03" v-show="showFlag">
          <div class="choiceItemTop">
            <img-item imgName="部署快" />
            <div class="Medium">部署快</div>
          </div>
          <div class="choiceItemText">
            灵活部署快速上线
          </div>
        </div>
        <div class="choiceItem itemFlashed flashDelay05" v-show="showFlag">
          <div class="choiceItemTop">
            <img-item imgName="成本低" />
            <div class="Medium">成本低</div>
          </div>
          <div class="choiceItemText">
            支持二次开发降低定制成本
          </div>
        </div>
        <div class="choiceItem itemFlashed flashDelay07" v-show="showFlag">
          <div class="choiceItemTop">
            <img-item imgName="敢创新" />
            <div class="Medium">敢创新</div>
          </div>
          <div class="choiceItemText">
            敢于创新敢突破
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
export default {
  components: {
    DisplayModule,
  },
  props: {
    showFlag: Boolean
  },
  data() {
    return {
      decorationImg: {
        'position': 'absolute',
        'width': '396px',
        'height': '268px',
        'bottom': 0,
        'right': 0
      },
      choiceMainImg: {
        "position": 'absolute',
        'top': 0,
        'left': 0,
        'right': 0,
        'bottom': 0,
        'width': '100%'
      },
      titleInfo: {
        ENGTitle: "choice",
        CHSTitle: "选择我们",
        instructions: [
          "多创科技精英团队，力求用最专业的技术和服务，为全国客户提供更精准高效的一站式互联网",
          "产品设计开发方案，为企业品牌赋能！",
        ],
      },
    };
  },
  methods: {},
  created() { },
};
</script>
<style scoped>
@keyframes itemFlash {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

.itemFlashed {
  animation: itemFlash 1.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.flashDelay01 {
  animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

.flashDelay03 {
  animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

.flashDelay05 {
  animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

.flashDelay07 {
  animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
}

.choiceMain {
  height: 1083px;
  width: 100%;
  padding-bottom: 84px;
  position: relative;
}

/* .decorationImg{
    position: absolute;
    width: 396px;
    height: 268px;
    bottom: 0;
    right: 0;
} */
.choiceContent {
  position: relative;
  width: 755px;
  margin: 0 auto;
}

.choiceList {
  display: flex;
  justify-content: center;
  align-items: center;
}

.choiceItem {
  margin: 0 38px;
  position: relative;
  z-index: 10;
}

.choiceItemTop {
  text-align: center;
}

.choiceItemTop>img {
  width: 69px;
  height: 69px;
}

.choiceItemTop>div {
  padding: 13px 0 11px;
  font-size: 18px;
  color: #3a416b;
  line-height: 25px;
  letter-spacing: 1px;
}

.choiceItemText {
  width: 132px;
  height: 81px;
  font-size: 15px;
  color: #6d738e;
  line-height: 20px;
  letter-spacing: 1px;
}</style>
