<template>
  <div class="datum">
    <div class="companyPhotos">
      <img-item class="companyAnimate" v-show="showFlag" imgName="编组 9" />
    </div>
    <div style="margin-left: 175px">
      <div class="datumEng Bold">about</div>
      <div class="datumTitle Medium animate__animated animate__fadeInUp" v-show="showFlag">关于多创</div>
      <div class="datumContent animate__animated animate__fadeInUp animate__slow" v-show="showFlag">
        多创信息科技（泉州）有限公司成立于2019年，坐落于文化底蕴浓郁的泉州，公司专注互联网各个领域APP开发、小程序开发、微信开发、管理系统开发等一站式互联网软件定制开发服务，致力于为客户量身打造更高效的互联网应用产品解决方案。
      </div>
      <div class="datumContent animate__animated animate__fadeInUp animate__slow" v-show="showFlag" style="margin-top: 30px">
        公司自成立以来不断自我迭代，紧跟网络信息技术不断发展，洞察瞬息万变的市场、为客户提供更优质的互联网综合服务。未来，我们不忘初心，将不断完善，结合市场需求，充分运用各方资源，发挥人才的优势，一切以客户利益需求为导向，坚决务实的为客户提供更好的互联网技术服务与支持。
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  props: {
    showFlag : Boolean
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.datum {
  padding: 0 50px;
  display: flex;
}
.companyPhotos {
  width: 591px;
  height: 675px;
  background: #2b7bed;
  position: relative;
}
.companyPhotos >>> img {
  position: absolute;
  left: 33px;
  bottom: -675px;
  width: 675px;
  height: 833px;
}
@keyframes pAnimate {
  0% {
    transform: translate(-100px,88px);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0,0);
  }
}
.companyAnimate {
  animation: pAnimate 2s ;
  animation-fill-mode : forwards
}
.datumEng {
  height: 62px;
  font-size: 54px;
  line-height: 74px;
  font-weight: bold;
  background: linear-gradient(180deg, #333333 0%, #ffffff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  opacity: 0.25;
}
.datumTitle {
  font-size: 36px;
  color: #333333;
  line-height: 49px;
  margin-top: -24px;
  margin-bottom: 16px;
  letter-spacing: 3px;
}
.datumContent {
  width: 431px;
  font-size: 18px;
  color: #333333;
  line-height: 38px;
  letter-spacing: 3px;
}
</style>
