<template>
  <div class="environmentMain">
    <display-module
      :titleInfo="titleInfo"
      v-show="showFlag"
      style="padding-top: 109px; margin-bottom: 83px"
    />
    <div class="environmentList">
      <div
        class="environmentItem animate__animated animate__fadeIn animate__slow flashDelay05"
      >
        <img-item  imgName="编组 2备份 4" :styleObj="environmentListImg"/>
        <div class="Medium">会议室</div>
      </div>
      <div
        class="environmentItem animate__animated animate__fadeIn animate__slow flashDelay1"
      >
        <img-item imgName="编组 2备份" :styleObj="environmentListImg"/>
        <div class="Medium">休息室</div>
      </div>
      <div
        class="environmentItem animate__animated animate__fadeIn animate__slow flashDelay05"
      >
        <img-item imgName="编组 2 (1)" :styleObj="environmentListImg"/>
        <div class="Medium">外部环境</div>
      </div>
    </div>
    <div class="environmentList">
      <div
        class="environmentItem animate__animated animate__fadeIn animate__slow flashDelay1"
      >
        <img-item imgName="编组 2备份 5" :styleObj="environmentListImg"/>
        <div class="Medium">技术部</div>
      </div>
      <div
        class="environmentItem animate__animated animate__fadeIn animate__slow flashDelay05"
      >
        <img-item imgName="编组 2备份 3" :styleObj="environmentListImg"/>
        <div class="Medium">业务部</div>
      </div>
      <div
        class="environmentItem animate__animated animate__fadeIn animate__slow flashDelay1"
      >
        <img-item imgName="编组 2备份" :styleObj="environmentListImg"/>
        <div class="Medium">办公区</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
export default {
  components: {
    DisplayModule,
  },
  props: {
    showFlag: Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "environment",
        CHSTitle: "公司环境",
        instructions: [],
        
      },
      environmentListImg: {
  'position': 'absolute',
  'width': '330px',
  'height': '225px',
  'left': 0,
  'right': 0,
  'top': 0,
  'bottom': 0
}
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.environmentMain {
  width: 100%;
  position: relative;
}
.environmentList {
  text-align: center;
  display: flex;
  justify-content: center;
}
.flashDelay05 {
  animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
.flashDelay1 {
  animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
.environmentItem {
  width: 330px;
  height: 225px;
  position: relative;
  margin: 0 26px 30px;
}
.environmentItem .Medium {
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
  letter-spacing: 1px;
  bottom: 30px;
  left: 50%;
  width: 76px;
  margin-left: -38px;
  opacity: 0;
}
.environmentItem:hover .Medium {
  opacity: 1;
}
.environmentItem:hover >>> img {
  animation: mymove 2s;
  animation-fill-mode: forwards;
  -webkit-animation: mymove 2s;
}
@keyframes mymove {
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  75% {
    transform: scale(1.1);
  }
}
</style>
