<template>
  <div class="mapMian">
    <div id="container"></div>
    <div class="infoContent">
      <div class="opacity5">多创信息科技（泉州）有限公司</div>
      <div class="infoContentItem" style="margin: 0 116px 0 134px">
        <img-item imgName="形状 2" />
        <div>
          <div class="opacity5">公司地址：</div>
          <div>泉州市晋江市青阳街道象峰写字楼2号楼1503室</div>
        </div>
      </div>
      <div class="infoContentItem">
        <img-item imgName="编组 16" />
        <div>
          <div class="opacity5">联系方式：</div>
          <div>13661377101</div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AMap from "AMap";
export default {
  data() {
    return {};
  },
  methods: {
    createdMap() {
      // 创建地图实例
      var map = new AMap.Map("container", {
        zoom: 23,
        center: [118.568955, 24.797779],
        resizeEnable: true,
      });

      // 以 icon URL 的形式创建一个途经点
      var viaMarker = new AMap.Marker({
        position: new AMap.LngLat(118.568955, 24.797779),
        // 将一张图片的地址设置为 icon
        size: (68, 85),
        icon:
          "http://dotron.cn:9000/dtron/编组 15.png",
        // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
        offset: new AMap.Pixel(-68, -85),
      });

      // 将 markers 添加到地图
      map.add([viaMarker]);
    },
  },
  mounted() {
    this.createdMap();
  },
};
</script>
<style scoped>
.mapMian {
  width: 100%;
  position: relative;
}
#container {
  width: 100%;
  height: 390px;
}
.opacity5{
  opacity: 0.75;
}
.infoContent {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 83px;
  background: #000000;
  opacity: 0.7;
  color: #fff;
  display: flex;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 2px;
  justify-content: space-around;
  align-items: center;
}
.infoContentItem {
  display: flex;
  align-items: center;
}
.infoContentItem >>> img {
  width: 32px;
  height: 32px;
  margin-right: 15px;
}
</style>
